






















import { Component, Vue, Prop } from "vue-property-decorator"
import House from "~/models/House"
import Conversation from "~/models/Conversation"
import { state } from "@/store"
import Messaging from "@/components/messaging/Messaging.vue"
import HouseCard from "@/components/house/HouseCard/HouseCard.vue"
import HouseMap from "@/components/house/HouseMap.vue"

@Component({
  components: {
    Messaging,
    HouseCard,
    HouseMap,
  },
})
export default class HouseMessages extends Vue {
  @Prop({ required: true }) house!: House
  @Prop({ default: false }) loading!: boolean
  conversation = new Conversation()
  interval = 0

  mounted() {
    this.interval = setInterval(() => this.loadConversation(), 100)
  }

  async loadConversation() {
    if (!this.house.deal_status) return
    clearInterval(this.interval)
    const deal_id = state.currentDealId()
    if (!deal_id) return
    const { houseId } = this.$route.params
    this.conversation.deal_id = deal_id
    this.conversation.house_id = parseInt(houseId)
    await this.conversation.fetch()
  }
}
